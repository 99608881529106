// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'

export const reimbursementList = createAsyncThunk('appUsers/reimbursementList', async (params, { }) => {
  const response = await axios.post('/employeesreimbursements/list', params)
  return {
    params,
    data: response.data.employeereimbursement.employeereimbursement,
    totalPages: response.data.employeereimbursement.total
  }
})

export const applyReimbursement = createAsyncThunk('appUsers/applyReimbursement', async (data, { dispatch, rejectWithValue }) => {
  try {
    await axios.post('/employeesreimbursements/create', data)
    const obj = {
      q: "",
      page: 1,
      perPage: 6,
      status: 'All',
      userId: data.userId
    }
    await dispatch(reimbursementList(obj))
    return id
  } catch (ex) {
    return rejectWithValue(ex.response)
  }

})

export const UpdateReimbursement = createAsyncThunk('appUsers/UpdateReimbursement', async (data, { dispatch, rejectWithValue }) => {
  try {
    await axios.post('/employeesreimbursements/update', data)
    const obj = {
      q: "",
      page: 1,
      perPage: 6,
      status: 'All',
      userId: data.userId
    }
    await dispatch(reimbursementList(obj))
    return id
  } catch (ex) {
    return rejectWithValue(ex.response)
  }

})

export const deleteReimbursement = createAsyncThunk('appUsers/deleteReimbursement', async (data, { dispatch }) => {
  await axios.post('/employeesreimbursements/delete', { id: data?.id, updatedBy: userId })
  const obj = {
    q: "",
    page: 1,
    perPage: 6,
    status: 'All',
    userId: data?.userId
  }
  await dispatch(reimbursementList(obj))
  return id
})

export const appEmployeeLeaveSlice = createSlice({
  name: 'appEmployeeLeave',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    employeeReimbursements: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(reimbursementList.fulfilled, (state, action) => {
        state.employeeReimbursements = action.payload.data
        state.total = action.payload.totalPages
        state.params = action.payload.params
      })     
  }
})

export default appEmployeeLeaveSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()

export const getData = createAsyncThunk('appRecurringInvoices/getData', async params => {
  const response = await axios.post('/recurringinvoices/list', params)
  return {
    params,
    data: response.data.recurringinvoices.recurringinvoices,
    totalPages: response.data.recurringinvoices.total
  }
})

export const getInvoice = createAsyncThunk('appRecurringInvoices/getInvoice', async id => {
  const response = await axios.post('/recurringinvoices/recurringtaskget', { id })
  return response.data.task
})

export const addInvoice = createAsyncThunk('appRecurringInvoices/addInvoice', async (task, { }) => {
  const response = await axios.post('/recurringinvoices/create', task)
  return response.data.task
})

export const updateInvoice = createAsyncThunk('appRecurringInvoices/updateInvoice', async (task, { }) => {
  const response = await axios.post('/recurringinvoices/update', task)
  return response.data.recurringinvoices
})

export const deleteInvoice = createAsyncThunk('appRecurringInvoices/deleteInvoice', async (id, { dispatch, getState }) => {
  await axios.post('/recurringinvoices/delete', { id, updatedBy: userId })
  await dispatch(getData(getState().recurringinvoice.params))
  return id
})

export const appRecurringInvoicesSlice = createSlice({
  name: 'appRecurringInvoices',
  initialState: {
    invoiceData: [],
    total: 1,
    params: {},
    allData: [],
    selectedInvoice: null,
    invoiceId: null,
    editflag: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.invoiceData = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedInvoice = null
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.selectedInvoice = action.payload
        state.invoiceId = null
      })     
  }
})

export default appRecurringInvoicesSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.post('/compliances/list', params)
  return {
    params,
    data: response.data.compliances.compliances,
    totalPages: response.data.compliances.total
  }
})

export const getCompliance = createAsyncThunk('appUsers/getCompliance', async id => {
  const response = await axios.post('/compliances/get', { id })
  return response.data.compliances
})

export const addCompliance = createAsyncThunk('appUsers/addCompliance', async (data, { }) => {
  const res = await axios.post('/compliances/create', data)
  return res.data.compliance
})

export const updateCompliance = createAsyncThunk('appUsers/updateCompliance', async (data, { }) => {
  const res = await axios.post('/compliances/update', data)
  return res.data.compliance
})

export const deleteCompliance = createAsyncThunk('appUsers/deleteCompliance', async (id, { dispatch, getState }) => {
  await axios.post('/compliances/delete', { id, updatedBy: userId })
  await dispatch(getData(getState().users.params))
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    complianceId: null,
    params: {},
    errors: null,
    allData: [],
    selectedCompliance: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.complianceId = null
      })
      .addCase(addCompliance.fulfilled, (state, action) => {
        state.complianceId = action.payload
      })
      .addCase(getCompliance.fulfilled, (state, action) => {
        state.selectedCompliance = action.payload
      })
  }
})

export default appUsersSlice.reducer

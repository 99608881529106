import { Settings } from 'react-feather'

export default [
  {
    id: 'preferencesetting',
    title: 'Customization',
    icon: <Settings />,
    navLink: '/setting/preference',
    action: 'read',
    resource: 'task'
  }
]
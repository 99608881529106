// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import { indianFormattedPrice } from '@src/helper/functionHelper.js'
import axios from '@src/configs/axios/axiosConfig'
import moment from 'moment'

export const getData = createAsyncThunk('appSalesOrgReport/getData', async params => {
  const response = await axios.post('/billinginvoice/list', params)

  return {
    params,
    data: response.data.billinginvoice.billinginvoice,
    total: response.data.billinginvoice.total
  }
})

const getTaxes = (taxes, name) => {
  const tax = taxes.find((obj) => {
    return JSON.parse(obj).tax_name_value === name
  })
  return tax ? JSON.parse(tax)?.tax_amount_calculated : 0
}

export const exportSales = createAsyncThunk('appSalesOrgReport/exportSales', async params => {
  const response = await axios.post('/billinginvoice/list', params)

  const result = await response.data.billinginvoice.billinginvoice.map((obj) => {

    const data = {}
    data['uniqueno'] = obj.uniqueno
    data['customername'] = obj.customername
    data['date'] = moment.unix(JSON.parse(obj.payment.replace('\n', '')).created_at).format('MMM D, Y')
    data['noofusers'] = obj.noofusers
    data['noofclients'] = obj.noofclients
    data['gstin'] = obj.clientdetails?.gstin
    data['state'] = obj.clientdetails?.state
    data['subtotal'] = indianFormattedPrice(obj.subtotalamount || 0)
    data['cgst'] = getTaxes(obj.taxes, 'CGST')
    data['sgst'] = getTaxes(obj.taxes, 'SGST')
    data['igst'] = getTaxes(obj.taxes, 'IGST')
    data['total'] = indianFormattedPrice(parseFloat(obj.totalamount || 0))

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Unique ID', 'Customer Name', 'Date', 'No of Users', 'No of Clients', 'GSTIN', 'State', 'Sub Total', 'CGST', 'SGST', 'IGST', 'Total']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Sales_Report.xlsx')

})

export const employeeList = createAsyncThunk('appSalesOrgReport/employeeList', async params => {
  const response = await axios.post('/billinginvoice/employeelist', params)

  return {
    params,
    data: response.data.billinginvoice.billinginvoice,
    total: response.data.billinginvoice.total
  }
})


export const exportEmployeeList = createAsyncThunk('appSalesOrgReport/exportEmployeeList', async params => {
  const response = await axios.post('/billinginvoice/employeelist', params)

  const result = await response.data.billinginvoice.billinginvoice.map((obj) => {

    const data = {}
    data['orgname'] = obj.orgname
    data['name'] = obj.name
    data['email'] = obj.email
    data['rolename'] = obj.rolename
    data['contactno'] = obj.contactno
    data['paymentstatus'] = obj.billingsts ? 'Yes' : 'No'

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Firm Name', 'Employee Name', 'Email', 'Role', 'Contact No', 'Payment Status']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Employee_Report.xlsx')

})


export const appSalesOrgReportSlice = createSlice({
  name: 'appSalesOrgReport',
  initialState: {
    data: [],
    sales: [],
    monthlySummaries: [],
    total: 1,
    revenueByUser: [],
    employeeList: [],
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(employeeList.fulfilled, (state, action) => {
        state.employeeList = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })


  }
})

export default appSalesOrgReportSlice.reducer

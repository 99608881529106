import { AlignJustify, UserCheck } from 'react-feather'

export default [
  {
    id: 'organizationsetting',
    title: 'Organization Setting',
    icon: <AlignJustify />,
    navLink: '/account-settings',
    action: 'read',
    resource: 'client'
  }
]

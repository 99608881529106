import { ShoppingCart, Circle } from 'react-feather'
export default [
  {
    id: 'services',
    title: 'Service',
    icon: <ShoppingCart />,
    navLink: '/services/list',
    action : 'read',
    resource : 'service'
  }
]
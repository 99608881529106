import Cookies from 'js-cookie'

export const activeOrganizationid = () => {
    const localVariable = localStorage.getItem('activeOrganization') ? JSON.parse(localStorage.getItem('activeOrganization')) : null
    return localVariable?.id ?? null
}

export const activeOrganization = () => {
    const localVariable = localStorage.getItem('activeOrganization') ? JSON.parse(localStorage.getItem('activeOrganization')) : null
    return localVariable ?? null
}

export const loggedInUser = () => {
    const localVariable = Cookies.get('userData')
    return localVariable ?? null
}

export const orgUserId = () => {
    const localVariable = localStorage.getItem('activeOrganization') ? JSON.parse(localStorage.getItem('activeOrganization')) : null
    return localVariable?.organizationuserid ?? null
}

export const desId = () => {
    const localVariable = localStorage.getItem('activeOrganization') ? JSON.parse(localStorage.getItem('activeOrganization')) : null 
    return localVariable?.designationid ?? null
}


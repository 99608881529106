import {  Circle, File } from 'react-feather'

export default [
  {
    id: 'attendancesetting',
    title: 'Attendance & Leave',
    icon: <File />,
    children: [
      {
        id: 'attendancescore',
        title: 'Attendance Score',
        icon: <Circle />,
        navLink: '/attendancesetting/attendancescore',
        action: 'read',
        resource: 'task'
      },
      {
        id: 'businessours',
        title: 'Business Hours',
        icon: <Circle />,
        navLink: '/attendancesetting/businesshours',
        action: 'read',
        resource: 'recurring-task'
      },
      {
        id: 'holidays',
        title: 'Holidays',
        icon: <Circle />,
        navLink: '/attendancesetting/holidays',
        action: 'read',
        resource: 'recurring-task'
      },
      {
        id: 'leavesetting',
        title: 'Leave Settings',
        icon: <Circle />,
        navLink: '/attendancesetting/leavesetting',
        action: 'read',
        resource: 'recurring-task'
      }
    ]
  }
]
// ** React Imports
import { useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'

// ** Icons Imports
import { Disc, X, Circle } from 'react-feather'

// ** Utils
//import { getUserData } from '@utils'
import { activeOrganization } from '@src/helper/sassHelper'
const activeOrg = activeOrganization()

const VerticalMenuHeader = props => {
  // ** Props
  const { menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover } = props

  // ** Vars
  //const user = getUserData()

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  return (
    <div className='navbar-header'>
      <ul className='nav navbar-nav flex-row justify-content-between'>
        <li className='nav-item nav-toggle d-flex'>
          <NavLink to='/dashboard' className='navbar-brand mt-0'>
            <Link to='/dashboard' className='navbar-brand mt-0'>
              <h2 className='brand-text mb-0'>{activeOrg ? activeOrg.name.split(' ')[0] : ''}</h2>
            </Link>
          </NavLink>
        </li>
        <li className='float-end'>
          <div className='nav-link modern-nav-toggle cursor-pointer d-flex'>
            <Toggler size={20} />
            <X onClick={() => setMenuVisibility(false)} className='toggle-icon icon-x d-block d-xl-none' size={20} />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default VerticalMenuHeader

// ** Icons Import
import { Circle, FilePlus } from 'react-feather'

export default [
  {
    id: 'sales',
    title: 'Invoice',
    icon: <FilePlus />,
    children: [
      {
        id: 'invoice',
        title: 'Invoice',
        icon: <Circle />,
        navLink: '/invoice/list',
        action: 'read',
        resource: 'invoice'
      },
      {
        id: 'reimbursement',
        title: 'Reimbursement',
        icon: <Circle />,
        navLink: '/reimbursement/list',
        action: 'read',
        resource: 'invoice'
      }
    ]

  }
]
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import moment from 'moment'

export const getData = createAsyncThunk('appSalesReport/getData', async params => {
  let response
  if (params.option === 'client') {
    response = await axios.post('/salesreport/salesbyclient', params)
  } else if (params.option === 'service') {
    response = await axios.post('/salesreport/salesbyservice', params)
  }
  return {
    params,
    data: response.data.taskinvoices.taskinvoices,
    total: response.data.taskinvoices.total
  }
})

export const getRevenueByUser = createAsyncThunk('appSalesReport/getRevenueByUser', async params => {
  const response = await axios.post('/salesreport/revenuebyuser', params)

  return {
    params,
    data: response.data.taskinvoices.invoices,
    total: response.data.taskinvoices.total
  }
})

export const getReimbursement = createAsyncThunk('appSalesReport/getReimbursement', async params => {
  const response = await axios.post('/salesreport/reimbursementlist', params)

  return {
    params,
    data: response.data.reimbursementinvoice.reimbursementinvoice,
    total: response.data.reimbursementinvoice.total
  }
})

export const getList = createAsyncThunk('appSalesReport/getList', async params => {
  const response = await axios.post('/salesreport/receivables', params)

  return {
    params,
    data: response.data.taskinvoices.invoices,
    total: response.data.taskinvoices.total
  }
})

const paymentstatusObj = [
  { id: '', name: "Select All" },
  { id: '5', name: "Pending", color: 'light-danger' },
  { id: '6', name: "Partially Paid", color: 'light-warning' },
  { id: '7', name: 'Paid', color: 'light-success' },
  { id: '11', name: 'Sent', color: 'light-secondary' }
]

export const exportSales = createAsyncThunk('appSalesReport/exportSales', async params => {
  const response = await axios.post('/salesreport/sales', params)

  const result = await response.data.taskinvoices.map((obj) => {

    const data = {}
    data['uniqueno'] = obj.uniqueno
    data['contactname'] = obj.contactname
    data['contactemail'] = obj.contactemail
    data['servicename'] = obj.servicename[0]
    data['invoicedate'] = moment(obj.invoicedate, 'x').format('MMM DD, Y')
    data['totalamount'] = obj.totalamount
    data['totaltaxamount'] = obj.totaltaxamount
    data['totaltdsamount'] = obj.totaltdsamount || 0
    data['totalpaidamount'] = obj.totalpaidamount || 0
    data['dueamount'] = obj.dueamount
    data['status'] = paymentstatusObj.find((item) => item.id === String(obj.paymentstatus)).name

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Unique ID', 'Client Name', 'Client Email', 'Service', 'Invoice Date', 'Invoice Amount', 'GST', 'TDS', 'Amount Paid', 'Balance', 'Status']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Sales_Report.xlsx')

})

export const exportReimbursement = createAsyncThunk('appSalesReport/exportReimbursement', async params => {
  const response = await axios.post('/salesreport/reimbursementlist', params)

  const result = await response.data.reimbursementinvoice.reimbursementinvoice.map((obj) => {

    const data = {}
    data['uniqueno'] = obj.uniqueno
    data['contactname'] = obj.contactname
    data['contactemail'] = obj.contactemail
    data['servicename'] = obj.reimbursementinvoiceitemsservicename
    data['invoicedate'] = moment(obj.invoicedate, 'x').format('MMM DD, Y')
    data['totalamount'] = obj.totalamount
    data['totalpaidamount'] = obj.totalpaidamount || 0
    data['dueamount'] = obj.dueamount
    data['status'] = paymentstatusObj.find((item) => item.id === String(obj.paymentstatus)).name

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Unique ID', 'Client Name', 'Client Email', 'Service', 'Challan Date', 'Challan Amount', 'Amount Paid', 'Balance', 'Status']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Reimbursement_Report.xlsx')

})

export const exportproposal = createAsyncThunk('appSalesReport/exportproposal', async params => {
  const response = await axios.post('/salesreport/sales', params)

  const result = await response.data.taskinvoices.map((obj) => {

    const data = {}
    data['uniqueno'] = obj.uniqueno
    data['contactname'] = obj.contactname
    data['contactemail'] = obj.contactemail
    data['servicename'] = obj.servicename[0]
    data['invoicedate'] = moment(obj.invoicedate, 'x').format('MMM DD, Y')
    data['totalamount'] = obj.totalamount
    data['totaltaxamount'] = obj.totaltaxamount
    data['status'] = 'Draft'

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Unique ID', 'Client Name', 'Client Email', 'Service', 'Invoice Date', 'Invoice Amount', 'GST', 'Status']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Proposal_Report.xlsx')

})

export const exportPaymentReceived = createAsyncThunk('appSalesReport/exportPaymentReceived', async params => {
  const response = await axios.post('/salesreport/paymentreceived', params)

  const result = await response.data.taskinvoices.invoicepayments.map((obj) => {

    const data = {}
    data['invoiceno'] = obj.invoiceno
    data['totalamount'] = obj.totalamount
    data['paymentstatus'] = obj.paymentstatus
    data['uniqueno'] = obj.uniqueno
    data['paymentdate'] = moment(obj.paymentdate, 'x').format('MMM DD, Y')
    data['referenceno'] = obj.referenceno
    data['totalamountpaid'] = obj.totalamountpaid
    data['amountwithheld'] = obj.amountwithheld || 0

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Invoice No', 'Invoice Amount', 'Payment Status', 'Payment No', 'Payment Date', 'Reference No', 'Amount Paid', 'With Held']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Payment_Received_Report.xlsx')

})

export const exportReceivables = createAsyncThunk('appSalesReport/exportReceivables', async params => {
  const response = await axios.post('/salesreport/receivables', params)

  const result = await response.data.taskinvoices.invoices.map((obj) => {

    const data = {}
    data['uniqueno'] = obj.uniqueno
    data['contactname'] = obj.contactname
    data['contactemail'] = obj.contactemail
    data['subtotalamount'] = obj.subtotalamount
    data['totaltaxamount'] = obj.totaltaxamount
    data['totalamount'] = obj.totalamount
    data['dueamount'] = obj.dueamount

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Invoice No', 'Client Name', 'Client Email', 'Sub Total', 'Total Tax Amount', 'Total Amount', 'Balance']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Receivables_Report.xlsx')

})

export const exportRevenueByUser = createAsyncThunk('appSalesReport/exportRevenueByUser', async params => {
  const response = await axios.post('/salesreport/revenuebyuser', params)

  const result = await response.data.taskinvoices.invoices.map((obj) => {

    const data = {}
    data['name'] = obj.name
    data['total_Alltime'] = obj.total_Alltime || 0
    data['total_Week'] = obj.total_Week || 0
    data['total_Month'] = obj.total_Month || 0
    data['total_Quarter'] = obj.total_Quarter || 0
    data['total_Year'] = obj.total_Year || 0
    data['total_Lastyear'] = obj.total_Lastyear || 0

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Name', 'All Time', 'This Week', 'This Month', 'This Quarter', 'This Year', 'Last Year']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Revenue_BYy_User_Report.xlsx')

})

export const exportSalesByClient = createAsyncThunk('appSalesReport/exportSalesByClient', async params => {
  const response = await axios.post('/salesreport/salesbyclient', params)

  const result = await response.data.taskinvoices.invoices.map((obj) => {

    const data = {}
    data['name'] = obj.name
    data['total_Alltime'] = obj.total_Alltime || 0
    data['total_Week'] = obj.total_Week || 0
    data['total_Month'] = obj.total_Month || 0
    data['total_Quarter'] = obj.total_Quarter || 0
    data['total_Year'] = obj.total_Year || 0
    data['total_Lastyear'] = obj.total_Lastyear || 0

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Name', 'All Time', 'This Week', 'This Month', 'This Quarter', 'This Year', 'Last Year']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Revenue_BYy_User_Report.xlsx')

})

export const getSales = createAsyncThunk('appSalesReport/getSales', async params => {
  const response = await axios.post('/salesreport/sales', params)

  return {
    params,
    data: response.data.taskinvoices.invoices,
    total: response.data.taskinvoices.total
  }
})


export const getPaymentList = createAsyncThunk('appSalesReport/getPaymentList', async params => {
  const response = await axios.post('/salesreport/paymentreceived', params)

  return {
    params,
    data: response.data.taskinvoices.invoicepayments,
    total: response.data.taskinvoices.total
  }
})

export const getSalesList = createAsyncThunk('appSalesReport/getSalesList', async params => {
  const response = await axios.post('/salesreport/taskinvoicelist', params)

  return {
    params,
    data: response.data.taskinvoices.invoices,
    total: response.data.taskinvoices.total
  }
})


export const exportExcelList = createAsyncThunk('appUsers/exportExcelList', async params => {
  const response = await axios.post(`/clients/exportToExcel`, params)

  const ws = XLSX.utils.json_to_sheet(response.data.clients)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'client.xlsx')

})

export const setBillingOrgId = createAsyncThunk('appUsers/setBillingOrgId', async id => {
  return id
})


export const appSalesReportSlice = createSlice({
  name: 'appSalesReport',
  initialState: {
    data: [],
    sales: [],
    monthlySummaries: [],
    total: 1,
    revenueByUser: [],
    params: {},
    billingOrgId: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setBillingOrgId.fulfilled, (state, action) => {
        state.billingOrgId = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getPaymentList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getSalesList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      }).addCase(getSales.fulfilled, (state, action) => {
        state.sales = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      }).addCase(getRevenueByUser.fulfilled, (state, action) => {
        state.revenueByUser = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getReimbursement.fulfilled, (state, action) => {
        state.revenueByUser = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
  }
})

export default appSalesReportSlice.reducer

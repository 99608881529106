import axios from '@src/configs/axios/axiosConfig'

export const indianFormattedPrice = (val) => {

  const value = String(val)

  let removedValue = ''
  let finalVal = ''
  if (value.includes('.')) {
    removedValue = value.slice(value.indexOf('.'))
    finalVal = value.replace(removedValue, '')
    finalVal = finalVal.toString()
  } else {
    finalVal = value.toString()
  }
  let lastThree = finalVal.substring(finalVal.length - 3)
  const otherNumbers = finalVal.substring(0, finalVal.length - 3)
  if (otherNumbers !== '') lastThree = `,${lastThree}`
  const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
  return res + (removedValue === '' ? '.00' : removedValue)
}

export const currencyConvert = async (base_currency, currencies) => {

  const res = await axios.get(
    `https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_C8lWFFJJ2Q2soCa9lcN99U1dc8yl7A4gUoXNyjhj&base_currency=${base_currency}&currencies=${currencies}`)


  return res?.data?.data[currencies]
}
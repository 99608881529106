import { CreditCard } from 'react-feather'

export default [
  {
    id: 'billigsetting',
    title: 'Billing',
    icon: <CreditCard />,
    navLink: '/settings/billing',
    action: 'read',
    resource: 'client'
  }
]

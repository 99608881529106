// ** Third Party Components
import { useState } from 'react'
import { ChevronDown, Circle, MoreVertical } from 'react-feather'
// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import axios from '@src/configs/axios/axiosConfig'
import { useNavigate } from 'react-router-dom'
import { activeOrganizationid } from '@src/helper/sassHelper'
const activeOrgId = activeOrganizationid()
import { activeOrgDetails } from '@src/views/apps/organization/store/index.js'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
const IntlDropdown = () => {

  const orgId = process.env.REACT_APP_DEMO_ORGANIZATION
  const navigate = useNavigate()
  const [accountOpt, setAccountOpt] = useState(1)
  const [liveaccountId, setLiveAccountId] = useState('')
  const dispatch = useDispatch()
  
  // ** Vars
  const user = Cookies.get('userData')
  const langObj = {
    1: 'Live',
    2: 'Demo'
  }

  const langObjColor = {
    1: 'bg-success',
    2: 'bg-warning'
  }

  const getOrganizationList = async () => {

    axios.post('organizationusers/usersorganization', { userId: JSON.parse(user)?.id })
      .then((res) => {
        const org = res.data.organizations[0]
        setLiveAccountId(org.id)
      }).catch(() => {

      })
  }

  const getOrganization = (id) => {

    axios.post('/organizations/getactiveorg', { id })
      .then(async (res) => {
        const org = res.data.organizations
        await dispatch(activeOrgDetails(org))
        localStorage.setItem('activeOrganization', JSON.stringify(org))
        await navigate('/dashboard')
        await navigate(0)

      })
      .catch((err) => {
        console.log(err)
      })

  }

  // ** Function to switch Language
  const handleLangUpdate = (e, val, orgId) => {
    setAccountOpt(val)
    getOrganization(orgId)
    e.preventDefault()
  }

  useState(() => {
    getOrganizationList()
    if (activeOrgId === orgId) {
      setAccountOpt(2)
    } else {
      setAccountOpt(1)
    }
  }, [])

  const iconstyle = {
    height: '12px',
    width: '12px'
  }

  return (
    <UncontrolledDropdown href='/' tag='li' className='dropdown-language nav-item'>
      <DropdownToggle href='/' tag='a' className='d-flex nav-link border rounded p-50 mx-50' onClick={e => e.preventDefault()} >
        <div className={`${langObjColor[accountOpt]} rounded mt-25`} style={iconstyle}></div>
        <span className={`selected-language ms-1 me-1`}>{langObj[accountOpt]}</span>
        <ChevronDown size={16} />
      </DropdownToggle>
      <DropdownMenu className='mt-0'>
        <DropdownItem onClick={e => handleLangUpdate(e, 1, liveaccountId)} className='d-flex'>
          <div className='bg-success rounded  mt-25' style={iconstyle}></div>
          <h6 className='ms-50'>Live</h6>
        </DropdownItem>
        <DropdownItem onClick={e => handleLangUpdate(e, 2, orgId)} className='d-flex'>
          <div className='bg-danger rounded  mt-25' style={iconstyle}></div>
          <h6 className='ms-50'>Demo</h6>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default IntlDropdown

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'

export const getData = createAsyncThunk('appUsers/getData', async params => {
    const response = await axios.post(`/digitalsignatures/licenselist`, params)
    return {
        params,
        data: response.data.digitalsignatures.digitalsignatures,
        totalPages: response.data.digitalsignatures.total
    }
})

export const exportData = createAsyncThunk('appUsers/exportData', async params => {
    const response = await axios.post(`/digitalsignatures/licenseExportToExcel`, params)

    const ws = XLSX.utils.json_to_sheet(response.data.clients)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
    FileSaver.saveAs(data, 'digitalsignatureslicense.xlsx')

})

export const appClientsSlice = createSlice({
    name: 'appClients',
    initialState: {
        data: [],
        total: 1,
        params: {},
        clientErrors: null,
        allData: [],
        selectedClient: null,
        clientId: null,
        clientInformations: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default appClientsSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import moment from 'moment'

export const getData = createAsyncThunk('appTasksReport/getData', async params => {
  let response
  if (params.filter === 'clients') {
    response = await axios.post('/taskreport/listbyclients', params)
  } else if (params.filter === 'users') {
    response = await axios.post('/taskreport/listbyorganizationusers', params)
  } else if (params.filter === 'services') {
    response = await axios.post('/taskreport/listbyservices', params)
  }
  return {
    params,
    data: response.data.result.results,
    total: response.data.result.total
  }
})
export const getTaskList = createAsyncThunk('appTasksReports/getTaskList', async params => {
  const response = await axios.post('/taskreport/lists', params)
  return {
    params,
    data: response.data.tasks.tasks,
    totalPages: response.data.tasks.total
  }
})

export const getTaskBilling = createAsyncThunk('appTasksReports/getTaskBilling', async params => {
  const response = await axios.post('/taskreport/taskbilling', params)
  return {
    params,
    data: response.data.tasks.tasks,
    totalPages: response.data.tasks.total
  }
})

export const exportTask = createAsyncThunk('appTasksReports/exportTask', async params => {
  let response
  if (params.filter === 'clients') {
    response = await axios.post('/taskreport/listbyclients', params)
  } else if (params.filter === 'users') {
    response = await axios.post('/taskreport/listbyorganizationusers', params)
  } else if (params.filter === 'services') {
    response = await axios.post('/taskreport/listbyservices', params)
  }

  const result = await response.data.result.results.map((obj) => {

    const data = {}
    data['name'] = obj.name
    data['completed'] = obj.completedtasks
    data['todo'] = obj.todotasks
    data['inprogress'] = obj.inprogresstasks
    data['senttoreview'] = obj.senttoreviewtasks
    data['requestchanges'] = obj.requestchangestasks
    data['total'] = obj.totaltasks

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Name', 'Completed Tasks', 'Pending Tasks', 'In Progress Tasks', 'Sent To review Tasks', 'Request Changes Tasks', 'Total Tasks']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Task_Report.xlsx')

})

const priorityOptions = ['', 'Low', 'Medium', 'High']
const statusOptions = ['', 'Pending', 'In progress', 'Completed', 'On Hold', 'Cancelled', 'Sent to Review', 'Request Changes']

export const exportTaskList = createAsyncThunk('appTasksReports/exportTask', async params => {
  const response = await axios.post('/taskreport/lists', params)
  const result = await response.data.tasks.tasks.map((obj) => {

    const data = {}
    data['taskid'] = obj.uniqueidentity
    data['client'] = obj.clientname
    data['service'] = obj.servicename
    data['priority'] = priorityOptions[obj.priority]
    data['taskstatus'] = statusOptions[obj.taskstatus]
    data['enddate'] = moment(obj.enddate, 'x').format('MMM DD, y')

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Task ID', 'Client', 'Service', 'Priority', 'Status', 'End Date']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Tasks.xlsx')

})

const paymentstatusObj = [
  { id: '', name: "Select All" },
  { id: '5', name: "Pending", color: 'light-danger' },
  { id: '6', name: "Partially Paid", color: 'light-warning' },
  { id: '7', name: 'Paid', color: 'light-success' },
  { id: '11', name: 'Sent', color: 'light-secondary' }
]

export const exportTaskBilling = createAsyncThunk('appTasksReports/exportTaskBilling', async params => {
  const response = await axios.post('/taskreport/taskbilling', params)
  const result = await response.data.tasks.tasks.map((obj) => {

    const data = {}
    data['taskid'] = obj.uniqueidentity
    data['client'] = obj.clientname
    data['service'] = obj.servicename
    data['assignee'] = obj.assignee.map((assign) => assign.name).toString()
    data['reviewer'] = obj.reviewer.map((assign) => assign.name).toString()
    data['taskstatus'] = statusOptions[obj.taskstatus]
    data['invocieid'] = obj.uniqueno
    data['totalamount'] = obj.totalamount
    data['paymentstatus'] = paymentstatusObj.find((oj) => parseInt(obj.paymentstatus) === parseInt(oj.id))?.name

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Task ID', 'Client', 'Service', 'Assignee', 'Reviewer', 'Task Status', 'Invoice ID', 'Amount (Inclusive of GST)', 'Payment Status']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Task_Billing_Report.xlsx')

})

const hoursFormat = (row) => {

  const decimalTimeString = row.totalhours ?? '0.0'
  let decimalTime = parseFloat(decimalTimeString)
  decimalTime = decimalTime * 60 * 60
  let hours = Math.floor((decimalTime / (60 * 60)))
  decimalTime = decimalTime - (hours * 60 * 60)
  let minutes = Math.floor((decimalTime / 60))

  if (hours < 10) {
    hours = `0${hours}`
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  return (`${isNaN(hours) ? '00' : hours}: ${isNaN(minutes) ? '00' : minutes}`)

}

export const exportList = createAsyncThunk('appTasksReports/exportTaskBilling', async params => {
  const response = await axios.post('/taskreport/tasktimesheet', params)
  const result = await response.data.result?.map((obj) => {

    const data = {}
    data['client'] = obj.clientname
    data['service'] = obj.servicename
    data['totalours'] = hoursFormat(obj)

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Client Name', 'Service Name', 'Total Hours']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Timesheet.xlsx')

})

export const timesheetList = createAsyncThunk('appTasksReports/timesheetList', async params => {
  const response = await axios.post('/taskreport/tasktimesheet', params)
  return {
    params,
    data: response.data.result.timesheets,
    totalPages: response.data.result.total
  }
})

export const appTasksReportSlice = createSlice({
  name: 'appTasksReport',
  initialState: {
    data: [],
    monthlySummaries: [],
    total: 1,
    params: {},
    taskBillingData: [],
    taskList: [],
    timesheetList: []
  },
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(getTaskList.fulfilled, (state, action) => {
        state.taskList = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getTaskBilling.fulfilled, (state, action) => {
        state.taskBillingData = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(timesheetList.fulfilled, (state, action) => {
        state.timesheetList = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

  }
})

export default appTasksReportSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()

export const getData = createAsyncThunk('appRecurringTasks/getData', async params => {
  const response = await axios.post('/recurring-task/list', params)
  return {
    params,
    data: response.data.tasks.tasks,
    totalPages: response.data.tasks.total
  }
})

export const getClient = createAsyncThunk('appRecurringTasks/getClient', async id => {
  const response = await axios.post('/clients/get', { id })
  return response.data.clients
})

export const getTask = createAsyncThunk('appRecurringTasks/getTask', async id => {
  const response = await axios.post('/tasks/recurringtaskget', { id })
  return response.data.task
})

export const addTask = createAsyncThunk('appRecurringTasks/addTask', async (task, { }) => {
  const response = await axios.post('/recurring-task/create', task)
  return response.data.task
})

export const updateTask = createAsyncThunk('appRecurringTasks/updateTask', async (task, { }) => {
  const response = await axios.post('/recurring-task/update', task)
  return response.data.task
})

export const deleteTask = createAsyncThunk('appRecurringTasks/deleteTask', async (id, { dispatch, getState }) => {
  await axios.post('/tasks/delete', { id, updatedBy: userId })
  await dispatch(getData(getState().recurring_task.params))
  return id
})

export const exportTask = createAsyncThunk('appRecurringTasks/exportTask', async params => {
  const response = await axios.post(`/tasks/exportToExcel`, params)

  const ws = XLSX.utils.json_to_sheet(response.data.tasks)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'recurring_task.xlsx')

})

export const appRecurringTasksSlice = createSlice({
  name: 'appRecurringTasks',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTask: null,
    taskId: null,
    editflag: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedTask = null
      })
      .addCase(getTask.fulfilled, (state, action) => {
        state.selectedTask = action.payload
        state.recurring_taskId = null
      })
  }
})

export default appRecurringTasksSlice.reducer

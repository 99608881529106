// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'


export const getTaxPayerDetails = createAsyncThunk('appGSTFiling/getTaxPayerDetails', async params => {
    const response = await axios.post(`/gstapi/getTaxPayer`, params)
    return {
        data: response.data.taxpayer
    }
})

export const verifyTaxPayer = createAsyncThunk('appGSTFiling/verifyTaxPayer', async params => {
    const response = await axios.post(`/gstapi/verifyTaxPayer`, params)
    return {
        data: response.data.taxpayer
    }
})

export const getReturnTrack = createAsyncThunk('appGSTFiling/getReturnTrack', async params => {
    const response = await axios.post(`/gstapi/getReturnTrack`, params)
    return {

        data: response.data.returns
    }
})

export const appGSTFilingSlice = createSlice({
    name: 'appGSTFiling',
    initialState: {
        taxPayer: null,
        returnTrack: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getTaxPayerDetails.fulfilled, (state, action) => {                
                state.taxPayer = action.payload.data
            })
            .addCase(verifyTaxPayer.fulfilled, (state, action) => {                
                state.taxPayer = action.payload.data
            })
            .addCase(getReturnTrack.fulfilled, (state, action) => {
                state.returnTrack = action.payload.data?.efiledlist
            })
    }
})

export default appGSTFilingSlice.reducer

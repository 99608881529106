// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()

export const getLeaveTypes = createAsyncThunk('appLeaveTypes/getLeaveTypes', async params => {
  const response = await axios.post(`/leavetypes/list`, params)
  return {
    params,
    data: response.data.leavetypes
  }
})

export const addLeaveTypes = createAsyncThunk('appLeaveTypes/addLeaveTypes', async (data, { dispatch, getState, rejectWithValue }) => {

  try {
    await axios.post(`/leavetypes/create`, data)
    await dispatch(getLeaveTypes(getState().leavesettings.params))
    return []
  } catch (ex) {
    return rejectWithValue(ex.response)
  }
})

export const updateLeaveTypes = createAsyncThunk('appLeaveTypes/updateLeaveTypes', async (data, { dispatch, getState, rejectWithValue }) => {
  try {
    await axios.post(`/leavetypes/update`, data)
    await dispatch(getLeaveTypes(getState().leavesettings.params))
    return []
  } catch (ex) {
    return rejectWithValue(ex.response)
  }

})

export const deleteLeaveTypes = createAsyncThunk('appLeaveTypes/deleteLeaveTypes', async (id, { dispatch, getState }) => {
  await axios.post('/leavetypes/delete', { id, updatedBy: userId })
  await dispatch(getLeaveTypes(getState().invoiceaccount.params))
  return id
})

export const appLeaveTypesSlice = createSlice({
  name: 'appLeaveTypes',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    leaveTypeError: null,
    leaveTypeSucess: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getLeaveTypes.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.leaveTypeSucess = false
      })
      .addCase(addLeaveTypes.rejected, (state, action) => {
        state.leaveTypeError = action.payload.data.errors
      })
      .addCase(updateLeaveTypes.rejected, (state, action) => {
        state.leaveTypeError = action.payload.data.errors
      })
      .addCase(addLeaveTypes.fulfilled, (state) => {
        state.leaveTypeSucess = true
      })
      .addCase(updateLeaveTypes.fulfilled, (state) => {
        state.leaveTypeSucess = true
      })

  }
})

export default appLeaveTypesSlice.reducer

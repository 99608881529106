import { ExternalLink } from 'react-feather'

export default [
  {
    id: 'integration',
    title: 'Integration',
    icon: <ExternalLink />,
    navLink: '/settings/integrations',
    action: 'read',
    resource: 'client'
  }
]

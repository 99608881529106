// ** React Imports
import { useEffect, useState } from 'react'

// ** Third Party Components
import Select from 'react-select'
import classnames from 'classnames'
import { selectThemeColors } from '@utils'
import { MoreVertical, Settings, X } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'

// ** Reactstrap Imports
import { Col, Input, Label } from 'reactstrap'

// ** Styles
import themeConfig from '@configs/themeConfig'
import '@styles/react/libs/react-select/_react-select.scss'
import { ReactSortable } from 'react-sortablejs'
import { setDashboardLayout } from '@src/views/dashboard/ecommerce/store/index.js'
import { useDispatch } from 'react-redux'
const Customizer = props => {
  // ** Props
  const {
    isRtl,
    layout,
    setIsRtl,
    setLayout,
    navbarType,
    footerType,
    transition,
    navbarColor,
    contentWidth,
    menuCollapsed,
    setLastLayout,
    setTransition,
    setNavbarType,
    setFooterType,
    setNavbarColor,
    setContentWidth,
    setMenuCollapsed
  } = props

  // ** State
  const [openCustomizer, setOpenCustomizer] = useState(false)
  const dispatch = useDispatch()
  // ** Toggles Customizer
  const handleToggle = e => {
    e.preventDefault()
    setOpenCustomizer(!openCustomizer)
  }

  const dashboardCadList = [
    { id: 0, name: 'Welcome Card' },
    { id: 1, name: 'Revenue Card' },
    { id: 2, name: 'Task Card' }
  ]

  const [dashBoardCadList, setDashboardCards] = useState([])

  const updatePositionfn = async () => {
    await dispatch(setDashboardLayout(dashBoardCadList))
    localStorage.setItem('dashboardLayout', JSON.stringify(dashBoardCadList))
  }

  useEffect(async () => {
    const list = localStorage.getItem('dashboardLayout')
    if (list) {
      setDashboardCards(JSON.parse(list))
      await dispatch(setDashboardLayout(JSON.parse(list)))
    } else {
      localStorage.setItem('dashboardLayout', JSON.stringify(dashboardCadList))
      setDashboardCards(dashboardCadList)
      await dispatch(setDashboardLayout(dashBoardCadList))
    }
  }, [])

  // ** Render Navbar Colors Options
  const renderNavbarColors = () => {
    const colorsArr = ['white', 'primary', 'secondary', 'success', 'danger', 'info', 'warning', 'dark']

    return colorsArr.map(color => (
      <li
        key={color}
        className={classnames(`color-box bg-${color}`, {
          selected: navbarColor === color,
          border: color === 'white'
        })}
        onClick={() => {
          setNavbarColor(color)
          const temp = { ...themeConfig }
          temp.layout.navbar.backgroundColor = color
          localStorage.setItem('themeConfig', JSON.stringify(temp))
        }
        }
      ></li>
    ))
  }

  // ** Render Navbar Type Options
  const renderNavbarTypeRadio = () => {
    const navbarTypeArr = [
      {
        name: 'floating',
        label: 'Floating',
        checked: navbarType === 'floating'
      },
      {
        name: 'sticky',
        label: 'Sticky',
        checked: navbarType === 'sticky'
      },
      {
        name: 'static',
        label: 'Static',
        checked: navbarType === 'static'
      },
      {
        name: 'hidden',
        label: 'Hidden',
        checked: navbarType === 'hidden'
      }
    ]

    return navbarTypeArr.map((radio, index) => {
      const marginCondition = index !== navbarTypeArr.length - 1

      if (layout === 'horizontal' && radio.name === 'hidden') {
        return null
      }

      return (
        <div key={index} className={classnames('form-check', { 'mb-2 me-1': marginCondition })}>
          <Input type='radio' id={radio.name} checked={radio.checked} onChange={() => {
            setNavbarType(radio.name)
            const temp = { ...themeConfig }
            temp.layout.navbar.type = radio.name
            localStorage.setItem('themeConfig', JSON.stringify(temp))
          }}
          />
          <Label className='form-check-label' for={radio.name}>
            {radio.label}
          </Label>
        </div>
      )
    })
  }

  // ** Render Footer Type Options
  const renderFooterTypeRadio = () => {
    const footerTypeArr = [
      {
        name: 'sticky',
        label: 'Sticky',
        checked: footerType === 'sticky'
      },
      {
        name: 'static',
        label: 'Static',
        checked: footerType === 'static'
      },
      {
        name: 'hidden',
        label: 'Hidden',
        checked: footerType === 'hidden'
      }
    ]

    return footerTypeArr.map((radio, index) => {
      const marginCondition = index !== footerTypeArr.length - 1

      return (
        <div key={index} className={classnames('form-check', { 'mb-2 me-1': marginCondition })}>
          <Input
            type='radio'
            checked={radio.checked}
            id={`footer-${radio.name}`}
            onChange={() => {
              setFooterType(radio.name)
              const temp = { ...themeConfig }
              temp.layout.footer.type = radio.name
              localStorage.setItem('themeConfig', JSON.stringify(temp))
            }}
          />
          <Label className='form-check-label' for={`footer-${radio.name}`}>
            {radio.label}
          </Label>
        </div>
      )
    })
  }

  // **  Router Transition Options
  const transitionOptions = [
    { value: 'fadeIn', label: 'Fade' },
    { value: 'fadeInLeft', label: 'Fade In Left' },
    { value: 'zoomIn', label: 'Zoom In' },
    { value: 'none', label: 'None' }
  ]

  // ** Get Current Transition
  const transitionValue = transitionOptions.find(i => i.value === transition)

  return (
    <div
      className={classnames('customizer d-none d-md-block', {
        open: openCustomizer
      })}
    >
      <a href='/' className='customizer-toggle d-flex align-items-center justify-content-center' onClick={handleToggle}>
        <Settings size={14} className='spinner' />
      </a>
      <PerfectScrollbar className='customizer-content' options={{ wheelPropagation: false }}>
        <div className='customizer-header px-2 pt-1 pb-0 position-relative'>
          <h4 className='mb-0'>App Customizer</h4>
          <p className='m-0'>Customize & Preview in Real Time</p>
          <a href='/' className='customizer-close' onClick={handleToggle}>
            <X />
          </a>
        </div>

        <hr />

        <div className='px-2'>
          <p className='fw-bold'>Dashboard Layout</p>
          <ReactSortable className='p-0' tag='ul' list={dashBoardCadList} setList={setDashboardCards} onSort={() => updatePositionfn()}>
            {
              dashBoardCadList?.map((obj, k) => {
                return (
                  <li className='w-75 cursor-pointer d-flex p-50 border border-1' key={k}>
                    <MoreVertical size={13} />
                    <span className='font-small-3'>{obj.name}</span>
                  </li>
                )
              })
            }
          </ReactSortable>
        </div>

        <hr />

        <div className='px-2'>

          {false &&
            <div className='mb-2'>
              <p className='fw-bold'>Content Width</p>
              <div className='d-flex'>
                <div className='form-check me-1'>
                  <Input
                    type='radio'
                    id='full-width'
                    checked={contentWidth === 'full'}
                    onChange={() => setContentWidth('full')}
                  />
                  <Label className='form-check-label' for='full-width'>
                    Full Width
                  </Label>
                </div>
                <div className='form-check'>
                  <Input
                    id='boxed'
                    type='radio'
                    checked={contentWidth === 'boxed'}
                    onChange={() => setContentWidth('boxed')}
                  />
                  <Label className='form-check-label' for='boxed'>
                    Boxed
                  </Label>
                </div>
              </div>
            </div>
          }
          {false && <div className='form-switch mb-2 ps-0'>
            <div className='d-flex'>
              <p className='fw-bold me-auto mb-0'>RTL</p>
              <Input type='switch' id='rtl' name='RTL' checked={isRtl} onChange={() => setIsRtl(!isRtl)} />
            </div>
          </div>
          }

          <div className='mb-2'>
            <div className='d-flex justify-content-between align-items-center'>
              <p className='fw-bold mb-0'>Router Transition</p>
              <Select
                theme={selectThemeColors}
                className='react-select'
                classNamePrefix='select'
                defaultValue={transitionOptions[0]}
                value={transitionValue}
                options={transitionOptions}
                isClearable={false}
                onChange={({ value }) => {
                  setTransition(value)
                  const temp = { ...themeConfig }
                  temp.layout.routerTransition = value
                  localStorage.setItem('themeConfig', JSON.stringify(temp))
                }}
              />
            </div>
          </div>
        </div>

        <hr />

        <div className='px-2'>
          <p className='fw-bold'>Menu Layout</p>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <div className='form-check me-1'>
                <Input
                  type='radio'
                  id='vertical-layout'
                  checked={layout === 'vertical'}
                  onChange={() => {
                    setLayout('vertical')
                    setLastLayout('vertical')
                    const temp = { ...themeConfig }
                    temp.layout.type = 'vertical'
                    localStorage.setItem('themeConfig', JSON.stringify(temp))
                  }}
                />
                <Label className='form-check-label' for='vertical-layout'>
                  Vertical
                </Label>
              </div>
              <div className='form-check'>
                <Input
                  type='radio'
                  id='horizontal-layout'
                  checked={layout === 'horizontal'}
                  onChange={() => {
                    setLayout('horizontal')
                    setLastLayout('horizontal')
                    const temp = { ...themeConfig }
                    temp.layout.type = 'horizontal'
                    localStorage.setItem('themeConfig', JSON.stringify(temp))
                  }}
                />
                <Label className='form-check-label' for='horizontal-layout'>
                  Horizontal
                </Label>
              </div>
            </div>
          </div>
          {layout !== 'horizontal' ? (
            <div className='form-switch mb-2 ps-0'>
              <div className='d-flex align-items-center'>
                <p className='fw-bold me-auto mb-0'>Menu Collapsed</p>
                <Input
                  type='switch'
                  id='menu-collapsed'
                  name='menu-collapsed'
                  checked={menuCollapsed}
                  onChange={() => {
                    setMenuCollapsed(!menuCollapsed)
                    const temp = { ...themeConfig }
                    temp.layout.menu.isCollapsed = !menuCollapsed
                    localStorage.setItem('themeConfig', JSON.stringify(temp))
                  }
                  }
                />
              </div>
            </div>
          ) : null}
        </div>

        <hr />

        <div className='px-2'>
          {layout !== 'horizontal' ? (
            <div className='mb-2'>
              <p className='fw-bold'>Navbar Color</p>
              <ul className='list-inline unstyled-list'>{renderNavbarColors()}</ul>
            </div>
          ) : null}

          <div className='mb-2'>
            <p className='fw-bold'>{layout === 'horizontal' ? 'Menu' : 'Navbar'} Type</p>
            <div className='d-flex'>{renderNavbarTypeRadio()}</div>
          </div>
        </div>

        <hr />

        <div className='px-2'>
          <div className='mb-0'>
            <p className='fw-bold'>Footer Type</p>
            <div className='d-flex'>{renderFooterTypeRadio()}</div>
          </div>
        </div>

      </PerfectScrollbar>
    </div>
  )
}

export default Customizer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()

export const getInvitations = createAsyncThunk('appUsers/getInvitations', async params => {
  const response = await axios.post('/invitations/list', params)
  return {
    params,
    data: response.data.invitations,
    totalPages: response.data.total
  }
})

export const inviteMail = createAsyncThunk('appUsers/inviteMail', async id => {
  await axios.post('/invitations/invitationmail', { id })
  return ''
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.post('/organizationusers/get', { id })
  return response.data.organizationuser
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  const res = await axios.post('/invitations/create', user)
  await dispatch(getData(getState().team.params))
  return res.data.invitation
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().team.params))
  return id
})

export const attendaceList = createAsyncThunk('appUsers/attendaceList', async (params, { }) => {
  const response = await axios.post('/employeeattendances/list', params)
  return {
    params,
    data: response.data.employeeattendances.employeeattendances,
    totalPages: response.data.employeeattendances.total
  }
})

export const leaveList = createAsyncThunk('appUsers/leaveList', async (params, { }) => {
  const response = await axios.post('/employeesleaves/list', params)
  return {
    params,
    data: response.data.employeesleaves.employeesleaves,
    totalPages: response.data.employeesleaves.total
  }
})

export const applyLeave = createAsyncThunk('appUsers/applyLeave', async (data, { dispatch, rejectWithValue }) => {
  try {
    await axios.post('/employeesleaves/create', data)
    const obj = {
      q: "",
      page: 1,
      perPage: 6,
      status: 'All',
      userId: data.userId
    }
    await dispatch(leaveList(obj))
    return id
  } catch (ex) {
    return rejectWithValue(ex.response)
  }

})

export const UpdateLeave = createAsyncThunk('appUsers/UpdateLeave', async (data, { dispatch, rejectWithValue }) => {
  try {
    await axios.post('/employeesleaves/update', data)
    const obj = {
      q: "",
      page: 1,
      perPage: 6,
      status: 'All',
      userId: data.userId
    }
    await dispatch(leaveList(obj))
    return id
  } catch (ex) {
    return rejectWithValue(ex.response)
  }

})

export const deleteLeave = createAsyncThunk('appUsers/deleteLeave', async (data, { dispatch }) => {
  await axios.post('/employeesleaves/delete', { id: data?.id, updatedBy: userId })
  const obj = {
    q: "",
    page: 1,
    perPage: 6,
    status: 'All',
    userId: data?.userId
  }
  await dispatch(leaveList(obj))
  return id
})

export const reimbursementList = createAsyncThunk('appUsers/reimbursementList', async (params, { }) => {
  const response = await axios.post('/employeesreimbursements/list', params)
  return {
    params,
    data: response.data.employeereimbursement.employeereimbursement,
    totalPages: response.data.employeereimbursement.total
  }
})

export const applyReimbursement = createAsyncThunk('appUsers/applyReimbursement', async (data, { dispatch, rejectWithValue }) => {
  try {
    await axios.post('/employeesreimbursements/create', data)
    const obj = {
      q: "",
      page: 1,
      perPage: 6,
      status: 'All',
      userId: data.userId
    }
    await dispatch(reimbursementList(obj))
    return id
  } catch (ex) {
    return rejectWithValue(ex.response)
  }

})

export const UpdateReimbursement = createAsyncThunk('appUsers/UpdateReimbursement', async (data, { dispatch, rejectWithValue }) => {
  try {
    await axios.post('/employeesreimbursements/update', data)
    const obj = {
      q: "",
      page: 1,
      perPage: 6,
      status: 'All',
      userId: data.userId
    }
    await dispatch(reimbursementList(obj))
    return id
  } catch (ex) {
    return rejectWithValue(ex.response)
  }

})

export const deleteReimbursement = createAsyncThunk('appUsers/deleteReimbursement', async (data, { dispatch }) => {
  await axios.post('/employeesreimbursements/delete', { id: data?.id, updatedBy: userId })
  const obj = {
    q: "",
    page: 1,
    perPage: 6,
    status: 'All',
    userId: data?.userId
  }
  await dispatch(reimbursementList(obj))
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    employeeLeaves: [],
    employeeReimbursements:[],
    leaveError: null,
    leaveSuccess: false,
    employeeAttendances: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getInvitations.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(leaveList.fulfilled, (state, action) => {
        state.employeeLeaves = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.leaveSuccess = false
      })
      .addCase(reimbursementList.fulfilled, (state, action) => {
        state.employeeReimbursements = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages        
      })
      .addCase(attendaceList.fulfilled, (state, action) => {
        state.employeeAttendances = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(applyLeave.fulfilled, (state) => {
        state.leaveSuccess = true
      })
      .addCase(UpdateLeave.fulfilled, (state) => {
        state.leaveSuccess = true
      })
  }
})

export default appUsersSlice.reducer

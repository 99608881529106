// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import moment from 'moment'

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.post('/tasktimesheets/list', params)
  return {
    params,
    data: response.data.tasktimesheets.tasktimesheets,
    totalPages: response.data.tasktimesheets.total
  }
})

export const getTimesheetList = createAsyncThunk('appUsers/getTimesheetList', async params => {
  const response = await axios.post('/tasktimesheets/listall', params)
  return {
    params,
    data: response.data.tasktimesheets
  }
})

export const timesheetList = createAsyncThunk('appUsers/timesheetList', async params => {
  let response
  if (params.option === 1) {
    response = await axios.post('/tasktimesheets/grouplist', params)
  } else if (params.option === 2) {
    response = await axios.post('/tasktimesheets/grouplistbyservice', params)
  }
  return {
    params,
    data: response.data.tasktimesheets
  }
})

const hoursFormat = (row) => {

  const decimalTimeString = row.totalhours
  let decimalTime = parseFloat(decimalTimeString)
  decimalTime = decimalTime * 60 * 60
  let hours = Math.floor((decimalTime / (60 * 60)))
  decimalTime = decimalTime - (hours * 60 * 60)
  let minutes = Math.floor((decimalTime / 60))

  if (hours < 10) {
    hours = `0${hours}`
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  return (`${isNaN(hours) ? '00' : hours}: ${isNaN(minutes) ? '00' : minutes}`)

}

export const exportList = createAsyncThunk('appUsers/exportList', async params => {
  const response = await axios.post('/tasktimesheets/grouplist', params)

  const result = await response.data.tasktimesheets.map((obj) => {

    const data = {}
    data['username'] = obj.username
    data['client'] = obj.clientname
    data['task'] = obj.taskname
    data['date'] = moment(obj.createdon, 'x').format('MMM DD, Y')
    data['start'] = moment(obj.starttime, 'x').format('h:m A')
    data['end'] = obj.endtime ? moment(obj.endtime, 'x').format('h:m A') : '00:00'
    data['total'] = hoursFormat(obj ?? 0)
    data['remarks'] = obj.remarks
    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['User', 'Client', 'Task', 'Date', 'Start Time', 'End Time', 'Total Hours', 'Remarks']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Timesheet.xlsx')

})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    timesheetList: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTimesheetList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
      })
      .addCase(timesheetList.fulfilled, (state, action) => {
        state.timesheetList = action.payload.data
        state.params = action.payload.params
      })
  }
})

export default appUsersSlice.reducer

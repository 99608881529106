// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

import useJwt from '@src/auth/jwt/useJwt'
import Cookies from 'js-cookie'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = Cookies.get('userData')
  //** Parse stored json or if none return initialValue
  return item ? item : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      const data = action.payload.users
      state.userData = data
      state[config.storageTokenKeyName] = action.payload['token']

      Cookies.set('userData', JSON.stringify(data), { domain: process.env.REACT_APP_PARENTDOMAIN })
      Cookies.set(config.storageTokenKeyName, action.payload.token, { domain: process.env.REACT_APP_PARENTDOMAIN })

    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('activeOrganization')
      Cookies.remove(config.storageTokenKeyName, { domain: process.env.REACT_APP_PARENTDOMAIN })
    Cookies.remove('defaultOrganizationId', { domain: process.env.REACT_APP_PARENTDOMAIN })      
      Cookies.remove('userData', { domain: process.env.REACT_APP_PARENTDOMAIN })
      Cookies.remove('activeOrganization', { domain: process.env.REACT_APP_PARENTDOMAIN })
      Cookies.remove('clientId', { domain: process.env.REACT_APP_PARENTDOMAIN })
      Cookies.remove('clientName', { domain: process.env.REACT_APP_PARENTDOMAIN })
      Cookies.remove('defaultOrganization', { domain: process.env.REACT_APP_PARENTDOMAIN })
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
import { UserCheck } from 'react-feather'

export default [
  {
    id: 'client',
    title: 'Client',
    icon: <UserCheck />,
    navLink: '/clients/list',
    action: 'read',
    resource: 'client'
  }
]

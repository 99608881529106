import { lazy } from 'react'
import BillingHistory from '../../views/pages/account-settings/BillingHistory'

const AccountSettings = lazy(() => import('../../views/pages/account-settings/AccountTabContent'))
const GeneralInvoiceSettings = lazy(() => import('../../views/pages/account-settings/Invoice/GeneralSetting'))
const InvoiceAccountsSettings = lazy(() => import('../../views/pages/account-settings/Invoice/InvoiceAccounts'))
const GSTSettingSettings = lazy(() => import('../../views/pages/account-settings/Invoice/TaxRateSetting'))
const ExemptionReasonSetting = lazy(() => import('../../views/pages/account-settings/Invoice/ExemptionReasonSetting'))
const Reimbursement = lazy(() => import('../../views/pages/account-settings/Invoice/Reimbursement'))

const AttendanceScoreSettings = lazy(() => import('../../views/pages/account-settings/Attendance/AttendanceScore'))
const BusinessHoursSettings = lazy(() => import('../../views/pages/account-settings/Attendance/BusinessHours'))
const HolidaysSettings = lazy(() => import('../../views/pages/account-settings/Attendance/Holidays'))
const LeaveSetting = lazy(() => import('../../views/pages/account-settings/Attendance/LeaveSetting'))

const DesignationSettings = lazy(() => import('../../views/pages/account-settings/RolesAndDesignation/Designation'))
const DepartmentSettings = lazy(() => import('../../views/pages/account-settings/RolesAndDesignation/Department'))
const RoleSettings = lazy(() => import('../../views/pages/account-settings/RolesAndDesignation/Roles'))

const Preference = lazy(() => import('../../views/pages/account-settings/Preference'))
const BIllingOrganizationSync = lazy(() => import('../../views/pages/account-settings/BIllingOrganizationSync'))

const ClientAccess = lazy(() => import('../../views/pages/account-settings/ClientAccess'))
const EmailTemplates = lazy(() => import('../../views/pages/account-settings/EmailTemplates'))
const ConnectionsTabContent = lazy(() => import('../../views/pages/account-settings/ConnectionsTabContent'))
const Modules = lazy(() => import('../../views/pages/account-settings/Modules'))
const BillingTabContent = lazy(() => import('../../views/pages/account-settings/BillingTabContent'))

const RecurringTaskList = lazy(() => import('../../views/apps/recurring-task/list'))
const RecurringTaskView = lazy(() => import('../../views/apps/recurring-task/view'))
const RecurringTaskAdd = lazy(() => import('../../views/apps/recurring-task/add'))
const RecurringTaskEdit = lazy(() => import('../../views/apps/recurring-task/edit'))

// const ComplianceList = lazy(() => import('../../views/apps/compliance/list'))
// const ComplianceView = lazy(() => import('../../views/apps/compliance/view'))
// const ComplianceAdd = lazy(() => import('../../views/apps/compliance/add'))
// const ComplianceEdit = lazy(() => import('../../views/apps/compliance/edit'))

const SettingRoutes = [

  {
    path: '/account-settings',
    element: <AccountSettings />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/invoicesetting/general',
    element: <GeneralInvoiceSettings />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/invoicesetting/invoiceaccount',
    element: <InvoiceAccountsSettings />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/invoicesetting/taxrate',
    element: <GSTSettingSettings />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/invoicesetting/exemptionreason',
    element: <ExemptionReasonSetting />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/invoicesetting/reimbursement',
    element: <Reimbursement />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/attendancesetting/attendancescore',
    element: <AttendanceScoreSettings />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/attendancesetting/businesshours',
    element: <BusinessHoursSettings />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/attendancesetting/holidays',
    element: <HolidaysSettings />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/attendancesetting/leavesetting',
    element: <LeaveSetting />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/settings/roles',
    element: <RoleSettings />,
    action: 'read',
    resource: 'role',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/settings/designation',
    element: <DesignationSettings />,
    action: 'read',
    resource: 'designation',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/settings/department',
    element: <DepartmentSettings />,
    action: 'read',
    resource: 'designation',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/settings/emailtemplates',
    element: <EmailTemplates />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/settings/integrations',
    element: <ConnectionsTabContent />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/settings/modules',
    element: <Modules />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/settings/billing',
    element: <BillingTabContent />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    path: '/settings/billing/history',
    element: <BillingHistory />,
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    element: <RecurringTaskList />,
    path: '/recurring-task/list',
    action: 'read',
    resource: 'task',
    meta: {
      layout: 'setting'
    }
  },
  {
    element: <RecurringTaskView />,
    path: '/recurring-task/view/:id',
    action: 'read',
    resource: 'task',
    meta: {
      layout: 'setting'
    }
  },
  {
    element: <RecurringTaskAdd />,
    path: '/recurring-task/add/',
    action: 'write',
    resource: 'task',
    meta: {
      layout: 'setting'
    }
  },
  {
    element: <RecurringTaskEdit />,
    path: '/recurring-task/edit/:id',
    action: 'write',
    resource: 'task',
    meta: {
      layout: 'setting'
    }
  },
  {
    element: <Preference />,
    path: '/setting/preference',
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    element: <BIllingOrganizationSync />,
    path: '/setting/billinorganization',
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  },
  {
    element: <ClientAccess />,
    path: '/setting/clientaccess',
    action: 'read',
    resource: 'setting',
    meta: {
      layout: 'setting'
    }
  }
  
]

export default SettingRoutes

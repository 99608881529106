// ** Icons Import
import { Book } from 'react-feather'

export default [
  {
    id: 'digital-signature',
    title: 'DSC Register',
    icon: <Book />,
    navLink: '/digital-signature/list',
    action : 'read',
    resource : 'digital-signature'
  }
]
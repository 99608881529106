import { CheckSquare, Circle, FilePlus } from 'react-feather'

export default [
  {
    id: 'invoicesetting',
    title: 'Invoice',
    icon: <FilePlus />,
    children: [
      {
        id: 'invoicegeneral',
        title: 'General',
        icon: <Circle />,
        navLink: '/invoicesetting/general',
        action: 'read',
        resource: 'task'
      },
      {
        id: 'invoiceaccount',
        title: 'Bank Accounts',
        icon: <Circle />,
        navLink: '/invoicesetting/invoiceaccount',
        action: 'read',
        resource: 'recurring-task'
      },
      {
        id: 'taxrate',
        title: 'Tax Rates',
        icon: <Circle />,
        navLink: '/invoicesetting/taxrate',
        action: 'read',
        resource: 'recurring-task'
      },
      {
        id: 'exemptionreason',
        title: 'Exemption Reason',
        icon: <Circle />,
        navLink: '/invoicesetting/exemptionreason',
        action: 'read',
        resource: 'recurring-task'
      },
      {
        id: 'reimbursement',
        title: 'Reimbursement',
        icon: <Circle />,
        navLink: '/invoicesetting/reimbursement',
        action: 'read',
        resource: 'recurring-task'
      }
    ]
  }
]
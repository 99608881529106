import { FileText } from 'react-feather'

export default [

  {
    id: 'feeds',
    title: 'Feeds',
    icon: <FileText />,
    navLink: '/feeds',
    resource: 'feeds'
  }

]
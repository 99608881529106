// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { orgUserId, activeOrganization } from '@src/helper/sassHelper'
import { sendMessage } from '@src/socketio.js'

let userId = orgUserId()
let org = activeOrganization()
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'

export const getUserProfile = createAsyncThunk('appChat/getTasks', async () => {
  userId = orgUserId()
  org = activeOrganization()
  const data = {
    id: userId,
    fullName: org.organizationusersname,
    role: org.rolename,
    about: '',
    driveid: localStorage.getItem('userImage')
  }
  return data
})

export const getChatContacts = createAsyncThunk('appChat/getChatContacts', async () => {
  userId = orgUserId()
  const response = await axios.post('/chatmessages/contactlist', { userId })
  return response.data
})

export const getChatGroups = createAsyncThunk('appChat/getChatGroups', async () => {
  const response = await axios.post('/chatmessages/listgroup', { userId })
  return response.data
})

export const getChat = createAsyncThunk('appChat/getChat', async (data, { }) => {
  userId = orgUserId()
  const response = await axios.post('/chatmessages/list', { toUserId: data.id, usertype: data.usertype, page: data.page, fromUserId: userId })
  return {
    chatmessages: response.data.chatmessages,
    refresh: data.refresh
  }
})

export const unreadCount = createAsyncThunk('appChat/unreadCount', async () => {
  const data = { id: userId }
  const response = await axios.post('/chatmessages/unreadcount', data)
  return response.data.count
})

export const chatUpdate = createAsyncThunk('appChat/chatUpdate', async (data, { dispatch }) => {
  userId = orgUserId()
  if (!data.updateflg) {
    await axios.post('/chatmessages/updatestatus', { toUserId: data.id, fromUserId: userId, type: data.usertype })
  }
  await dispatch(unreadCount())
  if (data.usertype === 1) {
    await dispatch(getChatGroups())
  } else {
    await dispatch(getChatContacts())
  }
  return {}
})

export const getChatSearch = createAsyncThunk('appChat/getChatSearch', async (data, { }) => {
  userId = orgUserId()
  const response = await axios.post('/chatmessages/list', { q: data.q, toUserId: data.toUserId, page: data.page, fromUserId: userId })
  return response.data
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (data, { dispatch }) => {
  const obj = { id: data.id, page: 1, usertype: data.usertype, refresh: true }
  if (data.unseenMsgs > 0) {
    await dispatch(chatUpdate(data))
  }
  await dispatch(getChat(obj))
  return data
})

export const sendMsgNotification = createAsyncThunk('appChat/sendMsgNotification', async (obj, { }) => {
  await axios.post('/notifications/sendnotifications', obj)
})

export const setChatNotification = createAsyncThunk('appChat/setChatNotification', async (obj, { }) => {
  return obj
})

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
  const response = await axios.post('/chatmessages/create', obj)
  //await dispatch(sendMsgNotification({ userId: obj.toUserId, data: response.data.chatmessage }))
  const msg = response.data.chatmessage
  await dispatch(setChatNotification(response.data.chatmessage))
  await sendMessage(msg.touserid, msg.fromuserid, msg)
  return response.data.chatmessage
})

export const deleteGroup = createAsyncThunk('appChat/deleteGroup', async (obj, { dispatch }) => {
  await axios.post('/chatmessages/deletegroup', obj)
  await dispatch(getChatGroups())
  return []
})

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chatObj: {
      chats: [],
      refresh: false
    },
    groups: [],
    contacts: [],
    userProfile: {},
    selectedUser: {},
    receivedMsg: {},
    searchList: [],
    notificationCount: 0
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        state.contacts = action.payload.organizationusers
      })
      .addCase(getChatGroups.fulfilled, (state, action) => {
        state.groups = action.payload.chatgroups
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getChat.fulfilled, (state, action) => {
        state.chatObj = {
          chats: action.payload.chatmessages.reverse(),
          refresh: action.payload?.refresh ?? false
        }

      })
      .addCase(getChatSearch.fulfilled, (state, action) => {
        state.searchList = action.payload.chatmessages
      })
      .addCase(setChatNotification.fulfilled, (state, action) => {
        state.receivedMsg = action.payload
      })
      .addCase(unreadCount.fulfilled, (state, action) => {
        state.notificationCount = action.payload
      })

  }
})

export default appChatSlice.reducer

import { Lock } from 'react-feather'

export default [
  {
    id: 'passwords',
    title: 'Passwords',
    icon: <Lock />,
    resource: 'passwords',
    navLink: '/passwords'
  }
]
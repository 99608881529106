// ** Navigation imports
import client from './client'
import leads from './leads'
import dashboard from './dashboards'
import service from './service'
import task from './task'
import attendance from './attendance'
import filemanager from './filemanager'
import sales from './sales'
import passwords from './passwords'
import digital_signature from './digital-signature'
import learning from './learning'
import feeds from '../horizontal/feeds'

// ** Merge & Export
export default [...dashboard, ...feeds, ...leads, ...client, ...service, ...sales, ...task, ...digital_signature, ...passwords, ...attendance, ...filemanager, ...learning]

// ** Dropdowns Imports
import IntlDropdown from './IntlDropdown'
//import CartDropdown from './CartDropdown'
import CardChat from './CardChat'
import UserDropdown from './UserDropdown'
//import GlobalAddDropDown from './GlobalAddDropDown'
import { activeOrganization } from '@src/helper/sassHelper'
//import NotificationDropdown from './NotificationDropdown'
import { useSelector } from 'react-redux'
// ** Third Party Components
import { Sun, Moon, Bell } from 'react-feather'
import Avatar from '@components/avatar'
// ** Reactstrap Imports
import { NavItem, NavLink } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
const activeOrg = activeOrganization()
const botImg = require('@src/assets/images/icons/technology/bot.png').default

const NavbarUser = props => {

  // ** Props
  const store = useSelector(state => state.chat)
  const [chatFlg, setChatFlg] = useState(false)
  const { skin, setSkin } = props
  const navigate = useNavigate()
  const EmailAlert = () => {

    return <Bell className='ficon' onClick={() => navigate('/emailalerts')} />

  }

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <Sun className='ficon' onClick={() => setSkin('light')} />
    } else {
      return <Moon className='ficon' onClick={() => setSkin('dark')} />
    }
  }

  return (
    <ul className='nav navbar-nav align-items-center ms-auto'>
      {activeOrg?.trialflag && <IntlDropdown />}      
      <NavItem className='d-none d-lg-block'>
        <NavLink className='nav-link-style'>
          <ThemeToggler />
        </NavLink>
      </NavItem>
      <NavItem className='d-none d-lg-block'>
        <NavLink className='nav-link-style'>
          <EmailAlert />
        </NavLink>
      </NavItem>
      <NavItem className='d-lg-block'>
        <Avatar
          imgHeight='36'
          imgWidth='36'
          img={botImg}
          onClick={() => setChatFlg(!chatFlg)}
          className='avatar-border cursor-pointer'
        />
      </NavItem>
      {chatFlg && <CardChat receivedMsg={store.receivedMsg} />}
      {/* <CardChat /> */}
      {/* <CartDropdown /> */}
      {/* <NotificationDropdown /> */}
      <UserDropdown />
    </ul>
  )
}
export default NavbarUser

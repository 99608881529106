import { CheckSquare, Circle } from 'react-feather'

export default [
  {
    id: 'taskmodule',
    title: 'Task',
    icon: <CheckSquare />,
    resource: 'task',
    action: 'read',
    children: [
      {
        id: 'task_summary',
        title: 'Summary',
        icon: <Circle />,
        navLink: '/task/summarylist',
        action: 'read',
        resource: 'task'
      },
      {
        id: 'tasks',
        title: 'Task',
        icon: <Circle />,
        navLink: '/task/list',
        action: 'read',
        resource: 'task'
      },
      {
        id: 'timesheet',
        title: 'Timesheet',
        icon: <Circle />,
        navLink: '/timesheet/list',
        action: 'read',
        resource: 'time-sheet'
      }
    ]
  }
]
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()

export const getServiceTemplates = createAsyncThunk('appTemplates/getServiceTemplates', async id => {
  const serviceId = id
  const response = await axios.post('/servicetemplates/list', { serviceId })
  return {
    data: response.data.servicetemplates
  }
})

export const addTemplates = createAsyncThunk('appTemplates/addTemplates', async (data, { dispatch }) => {
  await axios.post('/servicetemplates/create', data)
  await dispatch(getServiceTemplates(data.serviceId))
  return []
})

export const deleteTemplates = createAsyncThunk('appTemplates/deleteTemplates', async (data, { dispatch }) => {
  await axios.post('/servicetemplates/delete', { id: data.serid, updatedBy: userId })
  await dispatch(getServiceTemplates(data.id))
  return []
})

export const updateTemplates = createAsyncThunk('appTemplates/updateTemplates', async (data, { dispatch }) => {
  await axios.post('/servicetemplates/update', data)
  await dispatch(getServiceTemplates(data.serviceid))
  return []
})

export const appTemplatesSlice = createSlice({
  name: 'appTemplates',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getServiceTemplates.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
  }
})

export default appTemplatesSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()

export const importData = createAsyncThunk('appUsers/importData', async (form, { rejectWithValue }) => {
    try {
        await axios.post(`/${form.module}/${form.submodule}`, { rows: form.data, validationOnly: form.validationflag, createdBy: userId })
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const exportTask = createAsyncThunk('appTasks/exportTask', async params => {
    const response = await axios.post(`/tasks/exportToExcel`, params)

    const result = await response.data.tasks.map((obj) => {

        const data = {}
        data['uniqueidentity'] = obj.uniqueidentity
        data['priority'] = priorityOptions[obj.priority]
        data['taskstatus'] = statusOptions[obj.taskstatus]
        data['enddate'] = obj.enddate.length === 13 ? moment(obj.enddate, 'x').format("MMM DD, YYYY") : moment.unix(obj.enddate).format('MMM DD, YYYY')
        data['client'] = obj.clientname
        data['service'] = obj.servicename
        data['assignee'] = obj.Assignee.map((assign) => assign.name).toString()
        data['reviewer'] = obj.Reviewer.map((assign) => assign.name).toString()
        data['comment'] = obj.comment || ''

        return data
    })

    const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
    const Heading = [['Task Id', 'Prioirty', 'Status', 'End Date', 'Client', 'Service', 'Assignee', 'Reviewer', 'Last Comment']]
    XLSX.utils.sheet_add_aoa(ws, Heading)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
    FileSaver.saveAs(data, 'task.xlsx')

})

export const appImportSlice = createSlice({
    name: 'appClients',
    initialState: {
        importErrors: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(importData.rejected, (state, action) => {
                state.importErrors = action.payload.data.errors
            }).addCase(importData.fulfilled, (state) => {
                state.importErrors = []
            })
    }
})

export default appImportSlice.reducer

import { Folder } from 'react-feather'

export default [

  {
    id: 'filemanager',
    title: 'Files',
    icon: <Folder />,
    navLink: '/filemanager',
    resource: 'filemanager'
  }

]
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()


export const getTaskConversation = createAsyncThunk('appTasks/getTaskConversation', async (id, { }) => {
  const response = await axios.post('/taskconversations/list', { taskId: id })
  return {
    data: response.data.taskconversations
  }
})

export const addTaskConversation = createAsyncThunk('appTasks/addTaskConversation', async (data, { dispatch }) => {
  await axios.post('/taskconversations/create', data)
  await dispatch(getTaskConversation(data.taskId))
  return ''
})

export const updateTaskConversation = createAsyncThunk('appTasks/updateTaskConversation', async (data, { dispatch }) => {
  await axios.post('/taskconversations/update', data)
  await dispatch(getTaskConversation(data.taskid))
  return ''
})

export const deleteTaskConversation = createAsyncThunk('appTasks/deleteTaskConversation', async (data, { dispatch }) => {
  await axios.post('/taskconversations/delete', { id: data.id, taskId: data.taskId, updatedBy: userId })
  await dispatch(getTaskConversation(data.taskId))
  return ''
})

export const appTasksSlice = createSlice({
  name: 'appTasks',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTaskConversation.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
  }
})

export default appTasksSlice.reducer

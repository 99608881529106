import { UserX } from 'react-feather'

export default [
  {
    id: 'leads',
    title: 'Leads',
    icon: <UserX />,
    navLink: '/leads/list',
    action: 'read',
    resource: 'client'
  }
]

import { Circle, User } from 'react-feather'

export default [
  {
    id: 'rolesetting',
    title: 'Roles & Designation',
    icon: <User />,
    children: [
      {
        id: 'roles',
        title: 'Roles',
        icon: <Circle />,
        navLink: '/settings/roles',
        action: 'read',
        resource: 'task'
      },
      {
        id: 'designation',
        title: 'Designation',
        icon: <Circle />,
        navLink: '/settings/designation',
        action: 'read',
        resource: 'recurring-task'
      },
      {
        id: 'department',
        title: 'Department',
        icon: <Circle />,
        navLink: '/settings/department',
        action: 'read',
        resource: 'recurring-task'
      }
    ]
  }
]
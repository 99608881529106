// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'


export const getTaskWorkFlow = createAsyncThunk('appTaskWorkflows/getTask', async (data) => {
  const response = await axios.post('/taskworkflows/get', data)
  return response.data.taskworkflows
})

export const startTimer = createAsyncThunk('appTaskWorkflows/startTimer', async (data, { dispatch }) => {
  const response = await axios.post('/tasktimesheets/starttimer', data)
  await dispatch(getTaskWorkFlow({ id: data.taskWorkFlowId, userId: data.userId }))
  return response.data.timesheets
})

export const endTimer = createAsyncThunk('appTaskWorkflows/endTimer', async (data, { dispatch }) => {
  await axios.post('/tasktimesheets/endtimer', data)
  await dispatch(getTaskWorkFlow({ id: data.taskWorkFlowId, userId: data.userId }))
  return null
})

export const updateStatusRefresh = createAsyncThunk('appTaskWorkflows/updateStatusRefresh', async (data, { dispatch }) => {
  await dispatch(getTask({ id: data.taskId, userId: data.userId }))
  return ''
})

export const appTaskWorkflowsSlice = createSlice({
  name: 'appTaskWorkflows',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTaskWorkFlow: null,
    taskId: null,
    editflag: true,
    selectedTaskTimer: null
  },
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(getTaskWorkFlow.fulfilled, (state, action) => {
        state.selectedTaskWorkFlow = action.payload
        state.taskworkflowId = null
      })

  }
})

export default appTaskWorkflowsSlice.reducer

import { Circle, User } from 'react-feather'

export default [
  {
    id: 'teammodule',
    title: 'Team',
    icon: <User />,
    action: 'read',
    resource: 'team',
    children: [
      {
        id: 'team',
        title: 'Team',
        icon: <Circle />,
        navLink: '/team/list',
        action: 'read',
        resource: 'team'
      },
      {
        id: 'attendance',
        title: 'Attendance',
        icon: <Circle />,
        navLink: '/attendance/list',
        resource: 'attendance'
      },
      {
        id: 'leaves',
        title: 'Leave',
        icon: <Circle />,
        navLink: '/leaves/list',
        resource: 'leave'
      },
      {
        id: 'empeimbursement',
        title: 'Reimbursement',
        icon: <Circle />,
        navLink: '/employeereimbursement/list',
        resource: 'leave'
      }
    ]
  }
]
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()

export const fetchEvents = createAsyncThunk('appCalendar/fetchEvents', async params => {
  const response = await axios.post('/events/list', params)
  return { events: response.data.events, params }
})

export const addEvent = createAsyncThunk('appCalendar/addEvent', async (event, { dispatch, getState }) => {
  await axios.post('/events/create', event)
  await dispatch(fetchEvents(getState().calendar.params))
  return event
})

export const updateEvent = createAsyncThunk('appCalendar/updateEvent', async (data, { dispatch, getState }) => {
  await axios.post('/events/updateevent', { event: data, updatedBy: userId })
  await dispatch(fetchEvents(getState().calendar.params))
  return event
})

export const removeEvent = createAsyncThunk('appCalendar/removeEvent', async id => {
  await axios.post('/events/delete', { id, updatedBy: userId })
  return id
})

export const selectAll = createAsyncThunk('appCalendar/selectAll', async data => {
  return data
})

export const appCalendarSlice = createSlice({
  name: 'appCalendar',
  initialState: {
    events: [],
    selectedEvent: {},
    selectedCalendars: ['To Do', 'Holidays', 'Tasks', 'Recurring Tasks'],
    params: {}
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.params = action.payload.params
        state.events = action.payload.events
      })
      .addCase(selectAll.fulfilled, (state, action) => {
        state.selectedCalendars = action.payload
      })

  }
})

export const { selectEvent } = appCalendarSlice.actions

export default appCalendarSlice.reducer

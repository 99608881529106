import { Mail } from 'react-feather'

export default [
  {
    id: 'emailtemplates',
    title: 'Email Templates',
    icon: <Mail />,
    navLink: '/settings/emailtemplates',
    action: 'read',
    resource: 'client'
  }
]

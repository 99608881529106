import accountsetting from "./accountsetting"
import invoicesetting from "./invoicesetting"
import attendance from "./attendance"
import roles from "./roles"
import preference from "./preference"
//import recurringtask from "./recurringtask"
import billingorg from "./billingorg"
import emailtemplates from "./emailtemplates"
import modules from "./modules"
import integration from "./integration"
import billing from './billing'

export default [...accountsetting, ...invoicesetting, ...attendance, ...roles, ...preference, ...modules, ...emailtemplates, ...billingorg, ...integration, ...billing]

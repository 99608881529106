import { Book, Folder } from 'react-feather'

export default [

  {
    id: 'learning',
    title: 'Learning',
    icon: <Book />,
    navLink: 'https://learning.turia.in',
    resource: 'filemanager',
    newTab : true,
    externalLink: true,
    newTag: true
  }

]
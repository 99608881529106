// ** Icons Import
import { Home } from 'react-feather'

export default [
  {
    id: 'dashboards',
    title: 'Dashboard',
    icon: <Home />,
    action: 'read',
    resource: 'dashboard',
    navLink :'/dashboard'
  }
]

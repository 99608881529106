// ** React Imports
import { useState, useEffect } from 'react'

// ** Custom Components
import Avatar from '@components/avatar'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { MoreVertical, Send, Image } from 'react-feather'
import axios from '@src/configs/axios/axiosConfig'
// ** Reactstrap Imports
import { Card, CardHeader, Form, Label, InputGroup, Input, InputGroupText, Button } from 'reactstrap'
// ** Styles
import '@styles/base/pages/app-chat-list.scss'
const botImg = require('@src/assets/images/icons/technology/bot.png').default
import { orgUserId } from '@src/helper/sassHelper'
import { Link } from 'react-router-dom'
import moment from 'moment'
const profileImg = require('@src/assets/images/avatars/avatar-blank.png').default
const CardChat = ({ receivedMsg }) => {
  // ** States
  const [msg, setMsg] = useState('')
  const [chatRef, setChatRef] = useState(null)
  const [chatData, setChatData] = useState([])
  const userId = orgUserId()

  const getChatList = () => {
    axios.post('/notifications/botmessages', { userId })
      .then((res) => {
        setChatData(res.data.botmessages)
      })
  }

  useEffect(async () => {
    if (Object.keys(receivedMsg).length > 0) {
      if (!receivedMsg.fromuserid) {
        const tempmsg = chatData.find((obj) => obj.id === receivedMsg.id)
        if (!tempmsg) {
          const temp = [...chatData, receivedMsg]
          setChatData(temp)
        }
      }
    }
  }, [receivedMsg])

  //** Renders user chat
  const renderChats = () => {
    return chatData.map((item, index) => {
      return (
        <div
          key={index}
          className='chat chat-left'
        >
          <div className='chat-avatar'>
            <Avatar
              className='box-shadow-1 cursor-pointer'
              img={item.createrId ? `https://drive.google.com/thumbnail?id=${item.createrId}&authuser=0` : profileImg}
            />
          </div>
          <div className='chat-body'>
            <div key={item.id} className='chat-content p-50 w-100'>

              <div className='d-flex justify-content-between'>
                <span className='font-small-2'>{item.username}</span>
                <span className='font-small-2'>{moment(item.createdon, 'x').format('h:m a')}</span>
              </div>
              <p className='mt-75'>{item.message}</p>

              <Button color='primary' className='mt-50' outline size='sm' tag={Link} to={`/task/view/${item.modulereferenceid}`}>Vew Task</Button>
            </div>
          </div>
        </div>
      )
    })
  }

  //** Scroll to chat bottom
  const scrollToBottom = () => {
    chatRef.scrollTop = Number.MAX_SAFE_INTEGER
  }

  useEffect(() => {
    getChatList()
  }, [])

  useEffect(() => {
    if (chatRef !== null) {
      scrollToBottom()
    }
  }, [chatRef, chatData.length])

  const handleSendMsg = e => {
    e.preventDefault()
    if (msg.trim().length) {
      const newMsg = chatData

      newMsg.chat.chat.push({
        message: msg,
        time: new Date(),
        senderId: 11
      })

      setChatData(newMsg)
      setMsg('')
    }
  }

  return (
    <Card className='chat-widget me-1' style={{ position: 'absolute', top: '60px', right: 0, width: '35%' }}>
      <CardHeader className='chat-app-window'>
        <div className='d-flex align-items-center'>
          <Avatar status='online' className='me-2' img={botImg} imgHeight='34' imgWidth='34' />
          <h5 className='mb-0'>Turia Bot</h5>
        </div>
      </CardHeader>
      <div className='chat-app-window'>
        <PerfectScrollbar
          containerRef={el => setChatRef(el)}
          className='user-chats scroll-area'
          style={{ height: '490px' }}
          options={{ wheelPropagation: false }}
        >
          <div className='chats'>{renderChats()}</div>
        </PerfectScrollbar>
        {false &&
          <Form className='chat-app-form' onSubmit={e => handleSendMsg(e)}>
            <InputGroup className='input-group-merge me-1 form-send-message'>
              <InputGroupText>
                <Label className='attachment-icon mb-0' for='attach-doc'>
                  <Image className='cursor-pointer text-secondary' size={14} />
                  <input type='file' id='attach-doc' hidden />
                </Label>
              </InputGroupText>
              <Input
                value={msg}
                className='border-0'
                onChange={e => setMsg(e.target.value)}
                placeholder='Type your message'
              />
            </InputGroup>
            <Button className='send' color='primary'>
              <Send size={14} className='d-lg-none' />
              <span className='d-none d-lg-block'>Send</span>
            </Button>
          </Form>

        }
      </div>
    </Card>
  )
}

export default CardChat

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()
const statusOptions = ['', 'Active', 'In Active']
export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.post(`/leads/list`, params)
  return {
    params,
    data: response.data.leads.leads,
    totalPages: response.data.leads.total
  }
})

export const exportLead = createAsyncThunk('appUsers/exportLead', async params => {
  const response = await axios.post(`/leads/exportToExcel`, params)

  const result = await response.data.leads.map((obj) => {

    const data = {}
    data['name'] = obj.name
    data['contactperson'] = obj.contactpersonname
    data['email'] = obj.email
    data['contact'] = obj.contactnumber
    data['businessentity'] = obj.businessentityname
    data['status'] = statusOptions[obj.status]

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Name', 'Contact Person', 'Email', 'Contact', 'Business Entity', 'Status']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'leads.xlsx')

})

export const getLead = createAsyncThunk('appLeads/getLead', async id => {
  const response = await axios.post('/leads/get', { id })
  return response.data.leads
})

export const addLead = createAsyncThunk('appLeads/addLead', async (lead, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/leads/create`, lead)
    return { lead: response.data.leads }
  } catch (ex) {
    return rejectWithValue(ex.response)
  }
})

export const updateLead = createAsyncThunk('appLeads/updateLead', async (lead, { }) => {
  const response = await axios.post(`/leads/update`, lead)
  return { lead: response.data.leads }
})

export const deleteLead = createAsyncThunk('appLeads/deleteLead', async (id, { dispatch, getState }) => {
  await axios.post('/leads/delete', { id, updatedBy: userId })
  await dispatch(getData(getState().lead.params))
  return id
})

export const deleteContactInfo = createAsyncThunk('appLeads/deleteContactInfo', async (id, { }) => {
  await axios.post('/contactinformations/delete', { id, updatedBy: userId })
  return null
})

export const updateStatus = createAsyncThunk('appLeads/updateStatus', async (data, { dispatch, getState }) => {
  await axios.post(`/leads/statusupdate`, data)
  console.log(getState().lead)
  await dispatch(getData(getState().lead.params))
  return ''
})

export const updateBulk = createAsyncThunk('appLeads/updateStatus', async (data, { dispatch, getState }) => {
  await axios.post(`/leads/updateselectedrecords`, { ...data, updatedBy: userId })
  await dispatch(getData(getState().lead.params))
  return ''
})

export const appLeadsSlice = createSlice({
  name: 'appLeads',
  initialState: {
    data: [],
    total: 1,
    params: {},
    leadErrors: null,
    allData: [],
    selectedLead: null,
    leadId: null,
    leadInformations: [],
    mutualList: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedLead = null
      })
      .addCase(addLead.fulfilled, (state, action) => {
        state.leadId = action.payload.lead.id
      })
      .addCase(addLead.rejected, (state, action) => {
        state.leadErrors = action.payload.data.errors
      })
      .addCase(getLead.fulfilled, (state, action) => {
        state.selectedLead = action.payload
      })
  }
})

export default appLeadsSlice.reducer
